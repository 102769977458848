<template>
  <section id="text-filter">
    <label for="text-filter-input">
      {{ lang.metrics.filters.text.label[languageSelected] }}
    </label>
    <vs-input v-model="value" @input="change($event)" />
    <vs-button
      color="primary"
      type="flat"
      size="small"
      @click="clear"
      class="reset-button mt-1"
    >
      {{ lang.metrics.filters.range.clear[languageSelected] }}</vs-button
    >
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'TextFilter',
  data() {
    return {
      value: ''
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected'])
  },
  methods: {
    change(event, type = 'change') {
      this.$emit(type, {
        type: 'text',
        value: this.value
      })
    },
    reset() {
      this.value = ''
    },
    clear() {
      this.reset()
      this.change()
    }
  },
  mounted() {
    this.change(null, 'init')
  }
}
</script>

<style lang="scss">
#text-filter {
  display: flex;
  flex-direction: column;
  width: 320px;

  label {
    display: block;
  }
  .vs-input--input {
    width: 320px;
    min-height: 43px;
  }

  .reset-button {
    align-self: flex-end;
  }
}
</style>
